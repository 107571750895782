.rockit-fuel {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 20px;
}

.rockit-fuel .thanks-banner {
    background-color: rgb(157, 25, 29);
    font-weight: bold;
    line-height: 22px;
}

.rockit-fuel .thanks-banner .subline {
    font-weight: normal;
    line-height: 21px;
    font-size: 14px;
}

.rockit-fuel .intro-title {
    color: rgb(171, 3, 3);
    font-weight: 700;
    padding: 10px 40px 10px 40px;
}

.rockit-fuel .episode-container {
    display: grid;
}

.rockit-fuel .your-logo {
    text-align: right;
    width: 222px;
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}
.rockit-fuel .your-logo img {
    width: 143px;
    height: 143px;
}

.rockit-fuel .guest-name {
    grid-column: 1;
    width: 358px;
    text-align: left;
    padding-left: 40px;
    line-height: 24px;
}

.rockit-fuel .episode-title {
    grid-column: 1;
    width: 358px;
    text-align: left;
    padding-left: 40px;
    line-height: 24px;
}

.rockit-fuel .title-prelude {
    font-weight: 700;
    width: 100%;
    background-color:rgb(157, 25, 29);
    color: white;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}

.rockit-fuel .title-prelude .underlined {
    text-decoration: underline;
}

.rockit-fuel .title-prelude .title {
    color: white;
}

.rockit-fuel .intro {
    font-size: 18px;
}

.rockit-fuel .author-title {
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    padding-left: 40px;
}

.rockit-fuel .bio {
    font-size: 18px;
    line-height: 21.6px;
}

.rockit-fuel .call-to-action {
    font-size: 18px;

}

.rockit-fuel hr {
    margin-top: 20px;
    width: 90%;
}

.rockit-fuel .end-line {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.rockit-fuel .end-line .subline {
    line-height: 19.2px;
    font-size: 16px;
    margin-top: 10px;
}

.rockit-fuel .footer {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 10px;
    
}

.rockit-fuel .footer p {
    margin: 0;
}

.rockit-fuel .footer .separator {
    margin-bottom: 10px;
    padding: 0;
    width: 100%;
    height: 20px;
    background-color:rgb(157, 25, 29);
}
